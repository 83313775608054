import React from "react"
import Layout from "../components/Layout"
import big from "../assets/images/bgmain.jpg"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

require("typeface-titillium-web")

const Franja = styled.div`
  background: black;
  height: 60px;
`
const Spacer = styled.div`
  height: 70px;
`

const Box = styled.div`
  max-width: 400px;
  font-size: 20px;
  font-weight: 300;
  font-family: "Titillium Web";

  img {
    width: 400px;
  }
`
const TituloBox = styled.div`
  font-size: 40px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  a {
    text-decoration: none;
    color: black;
  }
`

const TituloBoxGrande = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 100px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -3px;
  a {
    text-decoration: none;
    color: black;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const Parrafo = styled.div`
  padding-top: 20px;
  padding-bottom: 40px;
  height: 300px;
`

//para espacio de spacer hacer pequeno el background
const spacer = 35

//para agrandamiento de segundo porcentaje de background
const percentage = 150

const Home = () => {
  return (
    <Layout sbiggo={big} spacer={spacer} percentage={percentage}>
      <Franja />
      <Spacer />
      <Container>
        <Row>
          <Col xs={12} lg={4}>
            <TituloBox>
              <Link to="/SitiosWeb">Sitios Web</Link>
            </TituloBox>
            <Box>
              <Parrafo>
                Yaku tiene más de 10 años de experiencia en la creación de
                sitios web. Hemos construido soluciones web a líderes del
                mercado Nuestros sitios web cuentan con tecnología de punta y
                diseño personalizado…
              </Parrafo>
              <StaticImage src="../assets/images/web01g.jpg" alt="web" />
            </Box>
          </Col>
          <Col xs={12} lg={4}>
            <TituloBox>
              <Link to="/AplicacionesWeb">Aplicaciones Web</Link>
            </TituloBox>
            <Box>
              <Parrafo>
                Desarrollamos soluciones web para proyectos específicos que
                requieren interacción con los usuarios, uso de bases de datos
                dinámicas, presentación de información dinámica…
              </Parrafo>
              <StaticImage src="../assets/images/web02b.jpg" alt="web" />
            </Box>
          </Col>
          <Col xs={12} lg={4}>
            <TituloBox>
              <Link to="/Reinge">Re Ingenieria Web</Link>
            </TituloBox>
            <Box>
              <Parrafo>
                Realizamos ajustes, cambios, modificaciones parciales o totales
                de sitios web. Nuestro proceso técnico incluye una asesoría para
                cambios a nivel hosting,, uso de tecnologías, mejoras en
                desempeño y velocidad, adaptación a dispositivos móviles…
              </Parrafo>
              <StaticImage src="../assets/images/web03b.jpg" alt="web" />
            </Box>
          </Col>
        </Row>
        <Row>
          <TituloBoxGrande>
            <Link to="/">Ultimas tendencias en tecnología Web </Link>
          </TituloBoxGrande>
        </Row>

        <Row>
          <Col xs={12} lg={4}>
            <TituloBox>
              <Link to="/Seguridad">Seguridad Web</Link>
            </TituloBox>
            <Box>
              <Parrafo>
                Mediante un proceso de asesoría y acompañamiento permanente
                detectamos y solucionamos problemas de seguridad en el sitio web
                y en el hosting. Implementamos soluciones de seguridad con
                componentes y servicios tecnológicos de punta, líderes en el
                mercado internacional…
              </Parrafo>
              <StaticImage src="../assets/images/web07.jpg" alt="security" />
            </Box>
          </Col>

          <Col xs={12} lg={4}>
            <TituloBox>
              <Link to="/Marketing">Marketing Digital</Link>
            </TituloBox>
            <Box>
              <Parrafo>
                Creamos estrategias en marketing digital que engloban todas las
                plataformas de marketing de su empresa para generar soluciones
                UX cuantificables y enfocadas a incrementar las ventas de sus
                servicios o productos…
              </Parrafo>
              <StaticImage src="../assets/images/web05.jpg" alt="marketing" />
            </Box>
          </Col>

          <Col xs={12} lg={4}>
            <TituloBox>
              <Link to="/Moviles">Aplicaciones Móviles</Link>
            </TituloBox>
            <Box>
              <Parrafo>
                Mediante el uso de tecnologías híbridas creamos aplicaciones
                móviles que se ajustan a las necesidades de cada proyecto.
                Generamos acompañamiento en todas las fases del desarrollo y
                puesta en marcha de cada solución hasta su publicación en los
                mercados de aplicaciones…
              </Parrafo>
              <StaticImage src="../assets/images/web06.jpg" alt="mobile" />
            </Box>
          </Col>
        </Row>
        <Spacer />
      </Container>
    </Layout>
  )
}

export default Home
